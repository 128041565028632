import React from 'react'
import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import Hero from '../sections/common/Hero'
import Content from '../sections/about/FeaturesContent'
import { Section, Text } from '../components/Core'
import { Container, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// import AppStore from '../assets/image/svg/appstore-black.svg'
// import PlayStore from '../assets/image/svg/playstore-colors.svg'
// import DarkMobile from '../assets/image/png/mobileNew.png'
// import WhiteMobile from '../assets/image/png/mobileNewWhite.png'
import platformHero from '../assets/image/svg/platform-hero.svg'
import managementImage from '../assets/image/png/management-portfolio.png'
import GetStarted from '../sections/landing1/GetStarted'
import { theme } from '../utils'
import styled from 'styled-components'

const ListItem = styled.li`
  font-size: 21px;
  font-weight: 300;
  padding-top: 0px;
  padding-bottom: 12px;
`

const Platform = () => {
  const { t, ready } = useTranslation(['platform'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const sectionBackgroundDark = userDarkMode
    ? theme.colors.black
    : theme.colors.lightGray

  return ready && (
    <>
      <MetaData page="platform" />
      <PageWrapper headerDark footerDark>
        <Hero
          bg={userDarkMode ? 'black' : 'bg'} 
          title={t('title')} 
          className="pb-5 mt-5"
          img={true}
          imgSrc={platformHero}
        >
          {t('subTitle')}
        </Hero>

        <Section bg={userDarkMode ? 'bgDarkGray' : 'light'}>
          <Container>
            <Row>
              <Col lg="4">
                <Text
                  className="mb-3"
                  variant="strong"
                  style={{ color: userDarkMode ? 'lightGray' : 'black'}}>
                  {t('builtWith')}
                </Text>
              </Col>
              <Col lg="8">
                <ul
                  className="ml-5 mb-3"
                  style={{ color: userDarkMode ? 'lightGray' : 'black'}}
                >
                  <ListItem type="disc">{t('leadingPrices')}</ListItem>
                  <ListItem type="disc">{t('manageAndTrade')}</ListItem>
                  <ListItem type="disc">{t('realTime')}</ListItem>
                  <ListItem type="disc">{t('ultraDeep')}</ListItem>
                  <ListItem type="disc">{t('layer2')}</ListItem>
                  <ListItem type="disc">{t('realTimePrice')}</ListItem>
                </ul>
              </Col>
            </Row>
          </Container>
        </Section>

        {/* Advanced Charting Section  */}
        <Content
          bullets={true}
          externalButton={true}
          btnRegular={true}
          title={t('advanced')}
          text1={t('bestCharts')}
          bullet2={t('manage')}
          bullet3={t('chartTypes')}
          bullet4={t('timeframes')}
          bullet5={t('saveCharting')}
          text5={t('ready')}
          extButtonText={t('signup')}
          extButtonUrl={process.env.LS_SIGNUP}/>

        {/* Competitive Pricing */}
        <Content
          button={true}
          table={false}
          btnRegular={true}
          title={t('competitive')}
          boldText={t('makeTheMost')}
          text2={t('lowestCost')}
          buttonText={t('morePricing')}
          buttonUrl="pricing"/>

        {/* Maximiz Your Crypto */}
        <Content
          button={true}
          btnRegular={true}
          title={t('maximize')}
          text1={t('seamlessly')}
          text2={t('streamline')}
          buttonText={t('landing.morePortfolio')}
          buttonUrl="portfolio-management"
          customBackground={userDarkMode ? 'bgDarkGray' : 'light'}
          customImage={managementImage}/>

        <GetStarted />
       
      </PageWrapper>
    </>
  )
}
export default Platform
